/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState, Suspense, lazy } from "react";
import AppContext from "./context/app/appContext";
import NavRoutes from "./NavRoutes";
import Sidebar from "./components/sidebar/Sidebar";
import LayoutContext from "./context/layout/layoutContext";
import { useMsal } from "@azure/msal-react";
import LoadComponent from "./components/global/LoadComponent";
import axios from "axios";
import { serverUrl } from "./authConfig";
import { BarLoader } from "react-spinners";

const Email = lazy(() => import("./components/email/Email"));

const AppContainer = () => {
  const { instance } = useMsal();
  const layoutContext = useContext(LayoutContext);

  const { changeLayoutState, desktopBreakpoint } = layoutContext;

  const appContext = useContext(AppContext);

  const underConstruction = false;

  const {
    RequestAccessToken,
    dbGet,
    changeAppState,
    staffTypeChecked,
    dbUser,
    changeStaffType,
  } = appContext;

  useEffect(() => {
    var head = document.head;
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = process.env.REACT_APP_CUSTOM_CSS;

    head.appendChild(link);

    return () => {
      head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    if (dbUser && !staffTypeChecked) {
      runChecks();
    }
    console.log(process.env);
  }, [dbUser, staffTypeChecked]);

  const runChecks = async () => {
    const res = await dbGet({
      type: "checkStaffTypes",
      staffId: dbUser.StaffId,
      isMulti: true,
    });

    // console.log("is teacher", res[0].length > 0);
    // console.log("is case manager", res[1].length > 0);

    var isTeacher = res[1].length > 0;
    var isCaseManager = res[0].length > 0;

    changeStaffType(isTeacher, isCaseManager);
  };

  const handleResize = () => {
    setContainerHeight(window.innerHeight - 70);
    if (window.outerWidth > desktopBreakpoint) {
      changeLayoutState("isDesktop", true);
    } else {
      changeLayoutState("isDesktop", false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  });

  const [pinned, setPinned] = useState(() => {
    return window.innerWidth < desktopBreakpoint ? false : true;
  });
  const [margin, setMargin] = useState("250");
  const [pinnable, setPinnable] = useState(() => {
    return window.innerWidth < desktopBreakpoint ? false : true;
  });

  useEffect(() => {
    pinned ? setMargin("280px") : setMargin("0");
  }, [pinned]);

  const checkAccess = async () => {
    const url = `${serverUrl}/permissions`;
    const tokenRes = await RequestAccessToken("protected");

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tokenRes.accessToken}`,
      },
      params: {
        isClientDev: process.env.NODE_ENV === "development" && "dev",
      },
    });

    return res.data.access;
  };

  const checkPermissions = async () => {
    const accessLevel = await checkAccess();

    if (accessLevel === 0 || accessLevel === 1) {
      changeAppState("privileges", accessLevel);
      const res = await RequestAccessToken("graph");

      const res2 = await dbGet({
        type: "checkUser",
        email: res.account.username,
      });

      if (res2.length > 0) {
        changeAppState("dbUser", {
          ...res2[0],
          CreatedBy: `${res2[0].Surname}, ${res2[0].FirstName} (${res2[0].StaffEmail})`,
        });
      } else {
        changeAppState("dbUser", {
          FirstName: "T'est User (Dev)",
          CreatedBy: `T'est User (Dev)`,
        });
      }
    } else {
      console.log("unauthorised");
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }

    // checkGroups(accessGroups, userGroups);
  };

  useEffect(() => {
    const runEffect = async () => {
      await checkPermissions();
      await checkAccess();
    };
    runEffect();
  }, []);

  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - 70
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  });

  if (!dbUser) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          width: "100%",

          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <h1 style={{ fontFamily: "Raleway,sans-serif" }}>
            Connecting to AARA Hub
          </h1>
        </div>
        <BarLoader />
      </div>
    );
  } else if (underConstruction) {
    return (
      <div style={{ padding: "3%" }}>
        <h2>Under construction...</h2>
        AARA Hub is getting some TLC over the holidays... see you back in Term
        3!
      </div>
    );
  } else {
    return (
      <div>
        <Sidebar
          pinnable={pinnable}
          setPinnable={setPinnable}
          setPinned={setPinned}
          pinned={pinned}
          setMargin={setMargin}
        />
        <div
          // className="main-container"
          style={{
            marginLeft: `${margin}`,
            height: `${containerHeight}px`,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NavRoutes />
          <Suspense fallback={<LoadComponent />}>
            <Email />
          </Suspense>
        </div>
      </div>
    );
  }
};

export default AppContainer;
